import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { LaudoContext } from "./LaudoContext";

const baseURL = "http://89.116.214.151:3000";

function GerenciadorDeImagens() {
  const [laudos, setLaudos] = useState([]);
  const [selectedLaudo, setSelectedLaudo] = useState("");
  const [ambientes, setAmbientes] = useState([]);
  const [selectedAmbiente, setSelectedAmbiente] = useState("");
  const [imagens, setImagens] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [concluidos, setConcluidos] = useState([]);
  const [laudosProducao, setLaudosProducao] = useState([]);
  const [agendamentosAtivos, setAgendamentosAtivos] = useState([]); // Novo estado para agendamentos ativos
  const { nomeLaudo, formData, imagensEnviadasPorLaudo } =
    useContext(LaudoContext); // Adicione imagensEnviadasPorLaudo

  const imagensEnviadas = imagensEnviadasPorLaudo[nomeLaudo] || 0;
  const imagensSuficientes = imagensEnviadas >= 2;

  // Função de util para checar se todos os campos de formData estão preenchidos
  const allFieldsFilled = (data) => {
    return Object.values(data).every(
      (value) => String(value || "").trim() !== ""
    );
  };

  const listarLaudos = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/laudosDisponiveis`);
      if (res.data.laudos && res.data.laudos.length > 0) {
        setLaudos(res.data.laudos);
        setSelectedLaudo("");
        setAmbientes([]);
        setSelectedAmbiente("");
        setImagens([]);
      } else {
        setError("Nenhum laudo disponível para listar imagens.");
      }
    } catch (error) {
      setError("Erro ao buscar laudos disponíveis.");
    } finally {
      setLoading(false);
    }
  };

  const listarLaudosProducao = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/laudosDisponiveis`);
      setLaudosProducao(res.data.laudos || []);
    } catch (error) {
      setError("Erro ao buscar laudos em produção.");
    } finally {
      setLoading(false);
    }
  };

  const removerLaudo = async (nomeLaudo, laudoId) => {
    setLoading(true);
    setError("");
    try {
      // Remove o laudo principal
      const resLaudo = await axios.delete(`${baseURL}/v1/removerLaudo`, {
        data: { nomeLaudo },
      });

      // Tenta remover o agendamento relacionado (se existir)
      try {
        await axios.delete(`${baseURL}/IA/v1/removerAgendamento/${laudoId}`);
      } catch (agendamentoError) {
        // Ignora erros de agendamento não encontrado
        if (agendamentoError.response?.status !== 404) {
          throw agendamentoError;
        }
      }

      setLaudosProducao((prev) =>
        prev.filter((l) => l.laudo_nome !== nomeLaudo)
      );
      setSuccessMessage(resLaudo.data.message);
    } catch (error) {
      setError(error.response?.data?.message || "Erro ao remover laudo");
    } finally {
      setLoading(false);
    }
  };

  const listarConcluidos = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/IA/v1/agendamentosConcluidos`);
      setConcluidos(res.data.agendamentos || []);
    } catch (error) {
      setError("Erro ao buscar agendamentos concluídos.");
    } finally {
      setLoading(false);
    }
  };

  const removerAgendamento = async (laudoId) => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.delete(
        `${baseURL}/IA/v1/removerAgendamento/${laudoId}`
      );
      if (res.status === 200) {
        setConcluidos((prev) =>
          prev.filter((agendamento) => agendamento.laudo_id !== laudoId)
        );
        setSuccessMessage(res.data.message);
      }
    } catch (error) {
      setError("Erro ao remover agendamento.");
    } finally {
      setLoading(false);
    }
  };

  const buscarAgendamentosAtivos = async () => {
    try {
      const res = await axios.get(`${baseURL}/IA/v1/agendamentosAtivos`);
      setAgendamentosAtivos(res.data.agendamentos || []);
    } catch (error) {
      setError("Erro ao buscar agendamentos ativos.");
    }
  };

  useEffect(() => {
    buscarAgendamentosAtivos();
  }, [laudosProducao]);

  const agendarLaudo = async (nomeLaudo) => {
    setLoading(true);
    setError("");
    try {
      // PEGA O HORÁRIO ATUAL EM ISO (UTC) E DEIXA O BACKEND CONVERTER PARA BRASÍLIA
      const horarioISO = new Date().toISOString();

      const response = await axios.post(`${baseURL}/IA/v1/agendarChamada`, {
        nomeLaudo,
        horario: horarioISO, // Envia horário atual
      });
      setSuccessMessage(response.data.message);
      buscarAgendamentosAtivos();
    } catch (error) {
      setError(error.response?.data?.message || "Erro ao agendar laudo.");
    } finally {
      setLoading(false);
    }
  };

  const handleLaudoChange = (laudoId) => {
    const laudo = laudos.find((l) => l.laudo_id === parseInt(laudoId, 10));

    if (laudo) {
      setSelectedLaudo(laudo.laudo_nome);
      setAmbientes(laudo.ambientes || []);
      setSelectedAmbiente("");
      setImagens([]);
    } else {
      setSelectedLaudo(""); // Reseta para evitar problemas
    }
  };

  const listarImagens = async () => {
    if (!selectedLaudo || !selectedAmbiente) {
      alert("Por favor, selecione um laudo e um ambiente.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/listarImagens`, {
        params: { nomeLaudo: selectedLaudo, nomeAmbiente: selectedAmbiente },
      });
      const imagens = res.data.imagens || [];
      if (imagens.length > 0) {
        setImagens(imagens);
      } else {
        setError(
          "Nenhuma imagem encontrada para o laudo e ambiente selecionados."
        );
      }
    } catch (error) {
      setError("Erro ao listar imagens.");
    } finally {
      setLoading(false);
    }
  };

  const gerarLaudo = async () => {
    setLoading(true);
    setError("");
    setSuccessMessage("");

    if (imagensEnviadas < 2) {
      alert("Por favor, envie pelo menos 2 imagens antes de gerar o laudo!");
      setLoading(false);
      return;
    }

    // Validação de campos preenchidos
    const camposVazios = Object.keys(formData).filter(
      (key) => String(formData[key] || "").trim() === ""
    );
    if (!nomeLaudo || camposVazios.length > 0) {
      let mensagemErro = "Os seguintes campos precisam ser preenchidos:\n";

      if (!nomeLaudo) mensagemErro += "- Nome do Laudo\n";
      if (camposVazios.length > 0) {
        mensagemErro += camposVazios.map((campo) => `- ${campo}`).join("\n");
      }

      alert(mensagemErro);
      setLoading(false);
      return;
    }

    const fakeAPIEndpoint = `${baseURL}/v1/gerarLaudo`;

    try {
      console.log("Request Payload:", { nomeLaudo, formData });

      const response = await axios.post(fakeAPIEndpoint, {
        nomeLaudo,
        formData,
      });
      setSuccessMessage(
        "Informações para a montagem do laudo enviadas com sucesso."
      );
    } catch (error) {
      console.error("Erro ao gerar laudo:", error);
      setError("Erro ao gerar o laudo.");
    } finally {
      setLoading(false);
    }
  };
  const visualizarPdfs = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/listarPDFsPesados`);
      if (res.data.files && res.data.files.length > 0) {
        setPdfs(res.data.files);
      } else {
        setError("Não existe nenhum laudo disponível para download.");
      }
    } catch (error) {
      setError("Não existe nenhum laudo disponível para download.");
    } finally {
      setLoading(false);
    }
  };

  const deletarPdfs = async () => {
    setLoading(true);
    setError("");
    setSuccessMessage("");
    try {
      const res = await axios.delete(`${baseURL}/v1/deletarPDFsPesados`);
      setSuccessMessage(res.data.message);
      setPdfs([]); // Limpar a lista de PDFs
    } catch (error) {
      setError("Erro ao deletar PDFs.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={listarLaudos} disabled={loading}>
        {loading ? "Carregando..." : "Listar Imagens"}
      </button>

      <button
        onClick={gerarLaudo}
        disabled={loading || !imagensSuficientes}
        style={{ marginLeft: "1rem" }}
        title={
          !imagensSuficientes
            ? "Aguardando envio de imagens para autorizar geração de laudo."
            : ""
        }
      >
        {loading ? "Carregando..." : "Gerar Laudo"}
      </button>

      <button
        onClick={listarLaudosProducao}
        disabled={loading}
        style={{ marginLeft: "1rem" }}
      >
        {loading ? "Carregando..." : "Em Produção"}
      </button>

      <button
        onClick={visualizarPdfs}
        disabled={loading}
        style={{ marginLeft: "1rem" }}
      >
        {loading ? "Carregando..." : "Baixar PDFs"}
      </button>

      <button
        onClick={deletarPdfs}
        disabled={loading || pdfs.length === 0}
        style={{ marginLeft: "1rem" }}
      >
        {loading ? "Carregando..." : "Resetar PDFs"}
      </button>

      <button
        onClick={listarConcluidos}
        disabled={loading}
        style={{ marginLeft: "1rem" }}
      >
        {loading ? "Carregando..." : "Listar Concluídos"}
      </button>

      {successMessage && <p className="success">{successMessage}</p>}
      {error && <p className="error">{error}</p>}

      {laudos.length > 0 && (
        <div
          className="selectors-container"
          style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}
        >
          <div className="laudo-selector">
            <h3>Selecione um laudo:</h3>
            <select
              value={selectedLaudo}
              onChange={(e) => handleLaudoChange(e.target.value)}
            >
              <option value="">Escolha um laudo</option>
              {laudos.map((laudo) => (
                <option key={laudo.laudo_id} value={laudo.laudo_id}>
                  {laudo.laudo_nome}
                </option>
              ))}
            </select>
          </div>

          {ambientes.length > 0 && (
            <div className="ambiente-selector">
              <h3>Selecione um ambiente:</h3>
              <select
                value={selectedAmbiente}
                onChange={(e) => setSelectedAmbiente(e.target.value)}
              >
                <option value="">Escolha um ambiente</option>
                {ambientes.map((ambiente, index) => (
                  <option key={index} value={ambiente}>
                    {ambiente}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      {selectedLaudo && selectedAmbiente && (
        <button
          onClick={listarImagens}
          disabled={loading}
          style={{ marginTop: "1rem" }}
        >
          {loading ? "Carregando..." : "Listar Imagens"}
        </button>
      )}

      <div className="image-list">
        {imagens.map((imagem, index) => (
          <div key={index} className="image-item">
            <p>{imagem.nome}</p>
            <img src={imagem.thumbnail} alt={imagem.nome} />
          </div>
        ))}
      </div>

      {pdfs.length > 0 && (
        <div className="pdf-list" style={{ marginTop: "2rem" }}>
          <h3>Laudos Disponíveis para Download:</h3>
          <ul>
            {pdfs.map((pdf, index) => (
              <li key={index}>
                <a
                  href={pdf.downloadLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {pdf.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Adicione aqui a nova seção de concluídos */}
      {concluidos.length > 0 && (
        <div className="concluidos-list" style={{ marginTop: "2rem" }}>
          <h3>Agendamentos Concluídos:</h3>
          <ul>
            {concluidos.map((agendamento) => (
              <li
                key={agendamento.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <div>
                  Horário: {agendamento.horario} | Status: {agendamento.status}{" "}
                  | Laudo ID: {agendamento.laudo_id}
                </div>
                <button
                  onClick={() => removerAgendamento(agendamento.laudo_id)}
                  disabled={loading}
                >
                  Remover Agendamento
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {laudosProducao.length > 0 && (
        <div className="producao-list" style={{ marginTop: "2rem" }}>
          <h3>Laudos em Produção:</h3>
          <ul>
            {laudosProducao.map((laudo) => {
              const agendamentoAtivo = agendamentosAtivos.find(
                (a) => a.laudo_id === laudo.laudo_id
              );
              return (
                <li key={laudo.laudo_id} style={{ marginBottom: "1rem" }}>
                  <div>
                    <strong>{laudo.laudo_nome}</strong> - Ambientes:{" "}
                    {laudo.ambientes.join(", ")}
                  </div>
                  <button
                    onClick={() =>
                      removerLaudo(laudo.laudo_nome, laudo.laudo_id)
                    }
                    disabled={loading}
                    style={{ marginTop: "0.5rem" }}
                  >
                    Remover Laudo
                  </button>
                  <button
                    onClick={() => agendarLaudo(laudo.laudo_nome)}
                    disabled={agendamentoAtivo || loading}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    {agendamentoAtivo
                      ? "Laudo Em Processamento"
                      : "Reagendar Laudo"}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div> // <- Este é o fechamento da div principal
  );
}

export default GerenciadorDeImagens;
