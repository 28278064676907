import minhaImagem from "../logo-mariah.png";
import logo_mariah_ia from "../mariah-ia-logo.png";

const LogoMariah = () => {
  return (
    <section>
      <div className="title-and-image">
        <img
          className="logo_mariah_ia"
          src={logo_mariah_ia}
          alt="Logo Mariah IA"
        />
        <img
          src={minhaImagem}
          alt="Descrição da imagem"
          className="mariah-image"
        />
      </div>
    </section>
  );
};

export default LogoMariah;
