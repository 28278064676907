import React, { useContext, useEffect } from "react";
import { LaudoContext } from "./LaudoContext";

const GerarLaudo = () => {
  // Pegamos o formData, setFormData e setNomeLaudo do contexto
  const { formData, setFormData, setNomeLaudo } = useContext(LaudoContext);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "tamanho") {
      if (/^\d*$/.test(value)) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        window.alert("Por favor, digite apenas números no campo Tamanho.");
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Atualiza nomeLaudo conforme "tipo", "endereco" e "realizada"
  useEffect(() => {
    const { tipo, endereco, realizada } = formData;
    if (tipo && endereco && realizada) {
      setNomeLaudo(`${tipo} - ${endereco} - ${realizada}`);
    }
  }, [formData.tipo, formData.endereco, formData.realizada, setNomeLaudo]); // Remover formData

  return (
    <div className="principal-gerador-de-laudos">
      <h2 className="texto-gerador-de-vistorias">Gerador de Vistorias</h2>
      {/* Removemos onSubmit, pois não queremos mais enviar nada aqui */}
      <form>
        <div className="Inputs-e-relatorio-geral-de-apontamentos">
          <div className="lados-form">
            <div className="form-lado-esquerdo-e-direito-juntos">
              <div className="form-lado-esquerdo">
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label id="">Uso:</label> <br />
                  <input
                    type="text"
                    name="uso"
                    value={formData.uso}
                    onChange={handleInputChange}
                    placeholder="Ex: Residencial"
                  />
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Tipo:</label>
                  <br />
                  <input
                    type="text"
                    name="tipo"
                    value={formData.tipo}
                    onChange={handleInputChange}
                    placeholder="Ex: Apartamento"
                  />
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Realizada:</label>
                  <br />
                  <input
                    type="text"
                    name="realizada"
                    value={formData.realizada}
                    onChange={handleInputChange}
                    placeholder="Ex: 16/10/2024 - 9h"
                  />
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Tipo de Vistoria:</label>
                  <br />
                  <select
                    name="tipoVistoria"
                    value={formData.tipoVistoria}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="Compra">Compra</option>
                    <option value="Entrada">Entrada</option>
                    <option value="Saida">Saida</option>
                  </select>
                </div>

                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Endereço:</label>
                  <br />
                  <input
                    type="text"
                    name="endereco"
                    value={formData.endereco}
                    onChange={handleInputChange}
                    placeholder="Ex: R. David Ben Gurion, 955 - Apto 81 - Torre 1 - São Paulo/SP"
                  />
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>CEP:</label>
                  <br />
                  <input
                    type="text"
                    name="cep"
                    value={formData.cep}
                    onChange={handleInputChange}
                    placeholder="Ex: 05634-001"
                  />
                </div>
              </div>
              <div className="form-lado-direito">
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Tamanho:</label>
                  <br />
                  <input
                    type="text"
                    name="tamanho"
                    value={formData.tamanho}
                    onChange={handleInputChange}
                    placeholder="Ex: 141"
                  />
                  <span> </span>
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Mobília:</label>
                  <br />
                  <select
                    name="mobilia"
                    value={formData.mobilia}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </select>
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Água:</label>
                  <br />
                  <select
                    name="agua"
                    value={formData.agua}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </select>
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Energia:</label>
                  <br />
                  <select
                    name="energia"
                    value={formData.energia}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </select>
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Pintura Nova:</label>
                  <br />
                  <select
                    name="pinturanova"
                    value={formData.pinturanova}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </select>
                </div>
                <div className="campo-input-gerar-primeira-e-ultima-pagina">
                  <label>Faxinado:</label>
                  <br />
                  <select
                    name="faxinado"
                    value={formData.faxinado}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione...</option>
                    <option value="Sim">Sim</option>
                    <option value="Não">Não</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="campo-input-gerar-primeira-e-ultima-pagina">
              <label>Link das imagens:</label>
              <br />
              <textarea
                name="link_das_imagens"
                value={formData.link_das_imagens}
                onChange={handleInputChange}
                placeholder="Ex: https://drive.google.com/drive/folders/143g4543tfJLgD_ZgbOTD346f3g3MC?hl=pt-br"
                className="campo-de-ambientes-gerar-laudo-primeira-e-ultima-pagina"
              />
            </div>
          </div>

          {/* Tabela com checkboxes */}
          <div className="relatorio-geral-de-apontamentos-check-box">
            <table>
              <thead>
                <tr className="titulo-relatorio-geral-de-apontamentos-e-Consta-Apontamento">
                  <div className="relatorio-geral">
                    <th>
                      <span className="texto-relatorio-geral">
                        RELATÓRIO GERAL
                      </span>
                      <br />
                      <span className="texto-de-apontamentos">
                        DE APONTAMENTOS
                      </span>
                    </th>
                  </div>
                  <div className="consta-apontamento">
                    <th colSpan="3">CONSTA APONTAMENTO</th>
                  </div>
                </tr>
                <tr className="titulo-sim-nao-inexiste">
                  <th></th>
                  <th>Sim</th>
                  <th>Não</th>
                  <th>Inexiste</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Testes Eletroeletrônicos</td>
                  <td>
                    <input
                      type="checkbox"
                      name="testes_eletro_eletronicos"
                      value="Sim"
                      checked={formData.testes_eletro_eletronicos === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="testes_eletro_eletronicos"
                      value="Não"
                      checked={formData.testes_eletro_eletronicos === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="testes_eletro_eletronicos"
                      value="Inexiste"
                      checked={
                        formData.testes_eletro_eletronicos === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>

                {}
                <tr>
                  <td>Teste de tomadas e interruptores</td>
                  <td>
                    <input
                      type="checkbox"
                      name="teste_de_tomadas_e_interruptores"
                      value="Sim"
                      checked={
                        formData.teste_de_tomadas_e_interruptores === "Sim"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="teste_de_tomadas_e_interruptores"
                      value="Não"
                      checked={
                        formData.teste_de_tomadas_e_interruptores === "Não"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="teste_de_tomadas_e_interruptores"
                      value="Inexiste"
                      checked={
                        formData.teste_de_tomadas_e_interruptores === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Luminárias e Spots</td>
                  <td>
                    <input
                      type="checkbox"
                      name="luminarias_e_spots"
                      value="Sim"
                      checked={formData.luminarias_e_spots === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="luminarias_e_spots"
                      value="Não"
                      checked={formData.luminarias_e_spots === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="luminarias_e_spots"
                      value="Inexiste"
                      checked={formData.luminarias_e_spots === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Fluxo e escoamento de água</td>
                  <td>
                    <input
                      type="checkbox"
                      name="fluxo_e_escoamento_de_agua"
                      value="Sim"
                      checked={formData.fluxo_e_escoamento_de_agua === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="fluxo_e_escoamento_de_agua"
                      value="Não"
                      checked={formData.fluxo_e_escoamento_de_agua === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="fluxo_e_escoamento_de_agua"
                      value="Inexiste"
                      checked={
                        formData.fluxo_e_escoamento_de_agua === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Vazamentos de sifões e flexíveis</td>
                  <td>
                    <input
                      type="checkbox"
                      name="vazamentos_de_sifoes_e_flexiveis"
                      value="Sim"
                      checked={
                        formData.vazamentos_de_sifoes_e_flexiveis === "Sim"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="vazamentos_de_sifoes_e_flexiveis"
                      value="Não"
                      checked={
                        formData.vazamentos_de_sifoes_e_flexiveis === "Não"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="vazamentos_de_sifoes_e_flexiveis"
                      value="Inexiste"
                      checked={
                        formData.vazamentos_de_sifoes_e_flexiveis === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Torneiras e Descargas</td>
                  <td>
                    <input
                      type="checkbox"
                      name="torneiras_e_descargas"
                      value="Sim"
                      checked={formData.torneiras_e_descargas === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="torneiras_e_descargas"
                      value="Não"
                      checked={formData.torneiras_e_descargas === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="torneiras_e_descargas"
                      value="Inexiste"
                      checked={formData.torneiras_e_descargas === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Box de Banheiro</td>
                  <td>
                    <input
                      type="checkbox"
                      name="box_de_banheiro"
                      value="Sim"
                      checked={formData.box_de_banheiro === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="box_de_banheiro"
                      value="Não"
                      checked={formData.box_de_banheiro === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="box_de_banheiro"
                      value="Inexiste"
                      checked={formData.box_de_banheiro === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Bancadas e Pias de pedra</td>
                  <td>
                    <input
                      type="checkbox"
                      name="bancadas_e_pias_de_pedra"
                      value="Sim"
                      checked={formData.bancadas_e_pias_de_pedra === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="bancadas_e_pias_de_pedra"
                      value="Não"
                      checked={formData.bancadas_e_pias_de_pedra === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="bancadas_e_pias_de_pedra"
                      value="Inexiste"
                      checked={formData.bancadas_e_pias_de_pedra === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Abertura de portas e janelas</td>
                  <td>
                    <input
                      type="checkbox"
                      name="abertura_de_portas_e_janelas"
                      value="Sim"
                      checked={formData.abertura_de_portas_e_janelas === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="abertura_de_portas_e_janelas"
                      value="Não"
                      checked={formData.abertura_de_portas_e_janelas === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="abertura_de_portas_e_janelas"
                      value="Inexiste"
                      checked={
                        formData.abertura_de_portas_e_janelas === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Maçanetas, fechaduras e trincos</td>
                  <td>
                    <input
                      type="checkbox"
                      name="macanetas_fechaduras_e_trincos"
                      value="Sim"
                      checked={
                        formData.macanetas_fechaduras_e_trincos === "Sim"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="macanetas_fechaduras_e_trincos"
                      value="Não"
                      checked={
                        formData.macanetas_fechaduras_e_trincos === "Não"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="macanetas_fechaduras_e_trincos"
                      value="Inexiste"
                      checked={
                        formData.macanetas_fechaduras_e_trincos === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pisos e Revestimentos</td>
                  <td>
                    <input
                      type="checkbox"
                      name="pisos_e_revestimentos"
                      value="Sim"
                      checked={formData.pisos_e_revestimentos === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="pisos_e_revestimentos"
                      value="Não"
                      checked={formData.pisos_e_revestimentos === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="pisos_e_revestimentos"
                      value="Inexiste"
                      checked={formData.pisos_e_revestimentos === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Pintura Geral</td>
                  <td>
                    <input
                      type="checkbox"
                      name="pintura_geral"
                      value="Sim"
                      checked={formData.pintura_geral === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="pintura_geral"
                      value="Não"
                      checked={formData.pintura_geral === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="pintura_geral"
                      value="Inexiste"
                      checked={formData.pintura_geral === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Esquadrias</td>
                  <td>
                    <input
                      type="checkbox"
                      name="esquadrias"
                      value="Sim"
                      checked={formData.esquadrias === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="esquadrias"
                      value="Não"
                      checked={formData.esquadrias === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="esquadrias"
                      value="Inexiste"
                      checked={formData.esquadrias === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sistema de ar-condicionado</td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_de_ar_condicionado"
                      value="Sim"
                      checked={formData.sistema_de_ar_condicionado === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_de_ar_condicionado"
                      value="Não"
                      checked={formData.sistema_de_ar_condicionado === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_de_ar_condicionado"
                      value="Inexiste"
                      checked={
                        formData.sistema_de_ar_condicionado === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sistema de aquecimento</td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_de_aquecimento"
                      value="Sim"
                      checked={formData.sistema_de_aquecimento === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_de_aquecimento"
                      value="Não"
                      checked={formData.sistema_de_aquecimento === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_de_aquecimento"
                      value="Inexiste"
                      checked={formData.sistema_de_aquecimento === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Persianas e Cortinas</td>
                  <td>
                    <input
                      type="checkbox"
                      name="persianas_e_cortinas"
                      value="Sim"
                      checked={formData.persianas_e_cortinas === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="persianas_e_cortinas"
                      value="Não"
                      checked={formData.persianas_e_cortinas === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="persianas_e_cortinas"
                      value="Inexiste"
                      checked={formData.persianas_e_cortinas === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Vidros e Vidraça</td>
                  <td>
                    <input
                      type="checkbox"
                      name="vidros_e_vidraca"
                      value="Sim"
                      checked={formData.vidros_e_vidraca === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="vidros_e_vidraca"
                      value="Não"
                      checked={formData.vidros_e_vidraca === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="vidros_e_vidraca"
                      value="Inexiste"
                      checked={formData.vidros_e_vidraca === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mobília Fixa e Mobília Planejada</td>
                  <td>
                    <input
                      type="checkbox"
                      name="mobilia_fixa_e_mobilia_planejada"
                      value="Sim"
                      checked={
                        formData.mobilia_fixa_e_mobilia_planejada === "Sim"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="mobilia_fixa_e_mobilia_planejada"
                      value="Não"
                      checked={
                        formData.mobilia_fixa_e_mobilia_planejada === "Não"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="mobilia_fixa_e_mobilia_planejada"
                      value="Inexiste"
                      checked={
                        formData.mobilia_fixa_e_mobilia_planejada === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Caixa de Disjuntores</td>
                  <td>
                    <input
                      type="checkbox"
                      name="caixa_de_disjuntores"
                      value="Sim"
                      checked={formData.caixa_de_disjuntores === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="caixa_de_disjuntores"
                      value="Não"
                      checked={formData.caixa_de_disjuntores === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="caixa_de_disjuntores"
                      value="Inexiste"
                      checked={formData.caixa_de_disjuntores === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>

                <tr>
                  <td>Espelhos</td>
                  <td>
                    <input
                      type="checkbox"
                      name="espelhos"
                      value="Sim"
                      checked={formData.espelhos === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="espelhos"
                      value="Não"
                      checked={formData.espelhos === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="espelhos"
                      value="Inexiste"
                      checked={formData.espelhos === "Inexiste"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Mobília Fixa e Mobília Móvel</td>
                  <td>
                    <input
                      type="checkbox"
                      name="mobilia_fixa_e_mobilia_movel"
                      value="Sim"
                      checked={formData.mobilia_fixa_e_mobilia_movel === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="mobilia_fixa_e_mobilia_movel"
                      value="Não"
                      checked={formData.mobilia_fixa_e_mobilia_movel === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="mobilia_fixa_e_mobilia_movel"
                      value="Inexiste"
                      checked={
                        formData.mobilia_fixa_e_mobilia_movel === "Inexiste"
                      }
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Sistema de Monitoramento</td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_monitoramento"
                      value="Sim"
                      checked={formData.sistema_monitoramento === "Sim"}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="sistema_monitoramento"
                      value="Não"
                      checked={formData.sistema_monitoramento === "Não"}
                      onChange={handleInputChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GerarLaudo;
