import React from "react";
import "./styles.css"; // Certifique-se de que está usando './' e não um caminho absoluto
import EnviarImagens from "./components/EnviarImagens";
import GerenciarArquivo from "./components/GerenciarArquivo";
import BuscarDescricoesDasImagens from "./components/BuscarDescricoesDasImagens";
import AtualizarApiKey from "./components/atualizarKey";
import LerApiKey from "./components/lerKey";
import LerEmailTo from "./components/lerEmailAtual";
import AtualizarEmailTo from "./components/visualizarEmailAtual";
// import AtualizarImagens from "./components/AtualizarImagens";
import GerarLaudo from "./components/gerarLaudo";
import LogoMariah from "./components/LogoMariah";
import { LaudoProvider } from "./components/LaudoContext";

function App() {
  return (
    <div className="container">
      <div className="PaginaPrincipal-LogoMariah">
        <LogoMariah />
      </div>
      <LaudoProvider>
        <div className="PaginaPrincipal-GerarLaudo">
          <GerarLaudo />
        </div>
        <div className="PaginaPrincipal-EnviarImagens">
          <EnviarImagens />
        </div>
        <div className="PaginaPrincipal-BuscarDescricoesDasImagens">
          <BuscarDescricoesDasImagens />
        </div>

        <div className="PaginaPrincipal-GerenciarArquivo">
          <GerenciarArquivo />
        </div>
        <div className="PaginaPrincipal-LerEmailTo">
          <LerEmailTo />
        </div>
        <div className="PaginaPrincipal-AtualizarEmailTo">
          <AtualizarEmailTo />
        </div>
        <div className="PaginaPrincipal-AtualizarApiKey">
          <AtualizarApiKey />
        </div>
        <div className="PaginaPrincipal-LerApiKey">
          <LerApiKey />
        </div>
        {/* <AtualizarImagens /> */}
      </LaudoProvider>
    </div>
  );
}

export default App;
