import React, { useState, useContext } from "react";
import axios from "axios";
import "../Spinner.css";
import { LaudoContext } from "../components/LaudoContext";

const baseURL = "http://89.116.214.151:3000/v1";

function EnviarImagens() {
  const [responseMessage, setResponseMessage] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState({ total: 0, completed: 0 });
  const { nomeLaudo, incrementarImagensEnviadas, camposObrigatorios } =
    useContext(LaudoContext); // Adicione incrementarImagensEnviadas

  const validarNomeArquivo = (nomeArquivo) => {
    const regexInvalido = /^\d+\s*\(\d+\)$/;
    return !regexInvalido.test(nomeArquivo.replace(/\.\w+$/, ""));
  };

  const extrairAmbienteENome = (nomeArquivo) => {
    const regex = /^(\d+\s*\(\d+\))\s*(.*?)(\.\w+)$/;
    const match = nomeArquivo.match(regex);
    if (match) {
      return {
        novoNome: match[1].trim() + match[3],
        ambiente: match[2].trim() || "Desconhecido",
      };
    }

    const coladoRegex = /^(.*?[a-zA-Z]+)(\d+\s*\(\d+\))(\.\w+)$/;
    const coladoMatch = nomeArquivo.match(coladoRegex);
    if (coladoMatch) {
      return {
        novoNome: coladoMatch[2].trim() + coladoMatch[3],
        ambiente: coladoMatch[1].trim() || "Desconhecido",
      };
    }

    const reverseRegex = /^(.*?)\s*(\d+\s*\(\d+\))(\.\w+)$/;
    const reverseMatch = nomeArquivo.match(reverseRegex);
    if (reverseMatch) {
      return {
        novoNome: reverseMatch[2].trim() + reverseMatch[3],
        ambiente: reverseMatch[1].trim() || "Desconhecido",
      };
    }

    return { novoNome: nomeArquivo, ambiente: "Desconhecido" };
  };

  const enviarImagens = async (event) => {
    const files = Array.from(event.target.files);

    // Modifique a validação para considerar camposObrigatorios
    if (camposObrigatorios && !nomeLaudo) {
      setResponseMessage(
        camposObrigatorios
          ? "Preencha todos os campos obrigatórios e tente novamente."
          : "Erro ao enviar imagens."
      );
      return;
    }

    setProgress({ total: files.length, completed: 0 });
    setLoading(true);
    setResponseMessage("");

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { novoNome, ambiente } = extrairAmbienteENome(file.name);
        const fileFormData = new FormData();

        const novoArquivo = new File([file], novoNome, { type: file.type });

        fileFormData.append("nomeLaudo", nomeLaudo);
        fileFormData.append("ambiente", ambiente);
        fileFormData.append("imagens", novoArquivo);

        console.log(`Enviando: ${novoNome}, Ambiente: ${ambiente}`);

        await axios.post(`${baseURL}/enviarImagens`, fileFormData);

        setProgress((prev) => ({
          total: prev.total,
          completed: prev.completed + 1,
        }));

        setUploadedImages((prev) => [...prev, file.name]);
      }
      incrementarImagensEnviadas(nomeLaudo, files.length);
      setResponseMessage("Imagens enviadas com sucesso!");
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Erro ao enviar as imagens.";
      setResponseMessage(errorMsg);
    } finally {
      setLoading(false);
      setUploadedImages([]);
      setProgress({ total: 0, completed: 0 });
    }
  };

  return (
    <section>
      <div className="MariahGeral">
        <p>
          {/* <strong>Nome do Laudo:</strong>{" "} */}
          {""}
          {/* {nomeLaudo || ""} */}
        </p>

        <input
          type="file"
          multiple
          onChange={enviarImagens}
          disabled={loading}
        />
      </div>

      {loading && (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>
            Enviando imagens ({progress.completed}/{progress.total})...
          </p>
        </div>
      )}

      {!loading && responseMessage && <p>{responseMessage}</p>}

      {!loading && uploadedImages.length > 0 && (
        <div>
          <h4>Imagens Enviadas:</h4>
          <ul>
            {uploadedImages.map((imageName, index) => (
              <li key={index}>{imageName}</li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
}

export default EnviarImagens;
