// LaudoContext.js
import React, { createContext, useState } from "react";

// Cria o contexto
export const LaudoContext = createContext();

// Provedor do contexto
export const LaudoProvider = ({ children }) => {
  const [nomeLaudo, setNomeLaudo] = useState("");
  const [formData, setFormData] = useState({
    uso: "",
    tipo: "",
    realizada: "",
    tipoVistoria: "",
    endereco: "",
    cep: "",
    tamanho: "",
    // mobilia: "",
    // energia: "",
    // agua: "",
    // ambientes: "",
    // pinturanova: "",
    // faxinado: "",
    // testes_eletro_eletronicos: "",
    // teste_de_tomadas_e_interruptores: "",
    // luminarias_e_spots: "",
    // fluxo_e_escoamento_de_agua: "",
    // vazamentos_de_sifoes_e_flexiveis: "",
    // torneiras_e_descargas: "",
    // box_de_banheiro: "",
    // bancadas_e_pias_de_pedra: "",
    // abertura_de_portas_e_janelas: "",
    // macanetas_fechaduras_e_trincos: "",
    // pisos_e_revestimentos: "",
    // pintura_geral: "",
    // esquadrias: "",
    // sistema_de_ar_condicionado: "",
    // sistema_de_aquecimento: "",
    // persianas_e_cortinas: "",
    // vidros_e_vidraca: "",
    // mobilia_fixa_e_mobilia_planejada: "",
    // caixa_de_disjuntores: "",
    // espelhos: "",
    // mobilia_fixa_e_mobilia_movel: "",
    // sistema_monitoramento: "",
  });
  const [imagensEnviadasPorLaudo, setImagensEnviadasPorLaudo] = useState({});

  const incrementarImagensEnviadas = (laudo, quantidade) => {
    setImagensEnviadasPorLaudo((prev) => ({
      ...prev,
      [laudo]: (prev[laudo] || 0) + quantidade,
    }));
  };

  return (
    <LaudoContext.Provider
      value={{
        nomeLaudo,
        setNomeLaudo,
        formData,
        setFormData,
        imagensEnviadasPorLaudo,
        incrementarImagensEnviadas,
      }}
    >
      {children}
    </LaudoContext.Provider>
  );
};
